* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Noto Sans KR", sans-serif;
  }
  pre 
  {
    white-space: pre-wrap; /* 형식이 유지되는 줄 바꿈 */
    word-wrap: break-word; /* 긴 단어를 자동으로 줄 바꿈하여 여러 줄로 표시 */
  }
  .background {
    min-height: 100vh;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.1);
  }
  .wrap {
    min-height: 90vh;
    width: 95%;
    height: 90%;
    background: white;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  h1
  {
    width : auto;
    height : auto;
    display: flex;
    text-align: center;
    align-items: center;
    position: relative;
    color: #ffa600;
    font-size: 2em;
    cursor : pointer;
  }
  h2 {
    width : 100%;
    height : auto;
    display: flex;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    text-align: center;
    justify-content: center;
}
  a
  {
    text-decoration: none;
    color: inherit;
    cursor: inherit;
  }
  li
  {
    list-style: none;
  }
  .login_btn_space
  {
    width : 30%;
    height : auto;
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  .login_btn_space ul
  {
    width : 100%;
    height : auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .login_btn
  {
    background-color: rgb(255, 255, 255);
    border: 0;
    text-decoration: underline;
    font-size: 0.7rem;
  }
  .login_btn:hover
  {
    color: #ffa600;
    cursor: pointer;
  }
  .btnsection
  {
    margin-top: 50px;
    width: 90%;
  }
  .btnsection > ul > li
  {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .test_btn
  {
    width: 80%;
    max-width: 1000px;
    height: 50px;
    border: 0;
    outline: none;
    border-radius: 40px;
    background: linear-gradient(to left, rgb(255, 77, 46), rgb(255, 155, 47));
    color: white;
    font-size: 1.2em;
    font-weight: bolder;
    cursor : pointer;
    margin-top: 30px;
  }
  .test_btn:hover
  {
    color: black;
  }

  .auth_container
  {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 7%;
  }
  .auth_container form
  {
    width: 80%;
    height: auto;
  }
  .input_place
  {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .input_place
  {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .select_place
  {
    width: 70%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .small_select_place
  {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .input_place input
  {
    width: 70%;
    height: 40px;
    border-radius: 30px;
    margin-top: 10px;
    padding: 0px 20px;
    border: 1px solid lightgray;
    outline: none;
    font-size: 1rem;
    text-align: center;
  }
  .input_place select
  {
    width: 100%;
    height: 40px;
    border-radius: 30px;
    margin-top: 10px;
    padding: 0px 20px;
    border: 1px solid lightgray;
    outline: none;
    font-size: 1rem;
    text-align: center;
  }
  .input_place label
  {
    width: 70%;
    height: auto;
    display: flex;
    margin-top: 10px;
    text-align: left;
    justify-content: left;
    align-items: left;
    font-weight: bolder;
    font-size: 1.3rem;
  }
  .login_etc 
  {
    margin-top: 8%;
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .login_etc label
  {
    font-size: 1.1rem;
    font-weight: 450;
    text-decoration: underline;
  }
  .login_etc label:hover, .login_etc span:hover, .radiobtn_place button:hover
  {
    color: #ffa600;
  }
  .login_etc span
  {
    font-size: 1.1rem;
    font-weight: 450;
  }
  .radiobtn_place
  {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-around;
    align-items: center;
    text-align:  center;
    margin-top: 1%;
  }
  .radiobtn_place button
  {
    width: 40%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
    font-weight: bolder;
    margin-top: 0;
    border : 0;
    background-color: white;
  }
  .radiobtn_place label
  {
    width: 50%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.8rem;
    margin-top: 0;
    border : 0;
    background-color: white;
  }
  .radiobtn_place label input
  {
    width: 10%;
    height: 100%;
    margin: 0;
    margin-right: 5%;
  }


  .btn_section
  {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 15px;
  }
  .control_info:hover
  {
    color: #ffa600;
  }
  .exam_register
  {
    background-color: white;
    border: 0;
    text-align: center;
    font-size: 1.3em;
    font-weight: bold;
    cursor: pointer;
    padding-bottom: 15px;
  }
  .exam_register:hover
  {
    color: #ffa600;
  }

  .info_table, .info_table tbody
  {
    margin-top: 50px;
    margin-bottom: 20px;
    border-top: 1px solid ;
    border-bottom : 1px solid;
    border-collapse: collapse;
    border-left: 0;
    border-right: 0;
    width: 90%;
  }
  .info_table tbody tr td
  {
    border-bottom : 1px solid;
    border-collapse: collapse;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 1em;
    word-break:keep-all;
  } 

  .record_table
  {
    margin-top: 10px;
    margin-bottom: 20px;
    border-top: 1px solid ;
    border-bottom : 1px solid;
    border-collapse: collapse;
    border-left: 0;
    border-right: 0;
    width: 90%;
  }
  .record_table thead
  {
    border-bottom : 4px double;
    empty-cells: hide;
  }
  .record_table tbody tr td
  {
    border-bottom : 1px solid;
    border-collapse: collapse;
    text-align: center;
    padding-left: 5px;
    font-size: 1em;
    word-break:keep-all;
  }

  .third_table, .third_table tbody, .third_table thead
  {
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 1px solid ;
    border-bottom : 1px solid;
    border-collapse: collapse;
    border-left: 0;
    border-right: 0;
    width: 95%;
  }
  .third_table tbody tr td, .third_table thead tr td
  {
    border-bottom : 1px solid;
    border-collapse: collapse;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 1em;
    word-break:keep-all;
  }
  .third_table_exam, .third_table_exam tbody, .third_table_exam thead
  {
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 1px solid ;
    border-bottom : 1px solid;
    border-collapse: collapse;
    border-left: 0;
    border-right: 0;
    width: 95%;
  }
  .third_table_exam tbody tr td, .third_table_exam thead tr td
  {
    border-bottom : 1px solid;
    border-collapse: collapse;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 0.8rem;
    word-break:keep-all;
    align-items: center;
    justify-content: center;
  }
  .details
  {
    text-decoration: underline;
    font-size: smaller;
  }
  .details:hover, .letter_btn:hover, .paging_number:hover, .small_letter_btn:hover
  {
    color : #ffa600;
    cursor: pointer;
  }

  .place
  {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .place > p, .sel_option > p
  {
    display: flex;
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
    color: #ffa600;
    margin-top: 10px;
  }
  .long_selection
  {
    width: 80%;
    text-align: center;
    font-weight: bolder;
    font-size: 1rem;
    border-radius: 5px;
    margin-top: 2%;
  }
  .short_selection
  {
    width: 60%;
    max-height: 1.5rem;
    text-align: center;
    font-weight: bolder;
    font-size: 1rem;
    border-radius: 5px;
    margin-top: 2%;
  }
  .normal_selection
  {
    width: 100%;
    text-align: center;
    font-weight: bolder;
    font-size: 1rem;
    border-radius: 5px;
  }
  .upper_button_place
  {
    width: 100%;
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: space-around;
    margin-top: 2%;
  }
  .upper_button_place > p
  {
    display: flex;
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
  }
  .upper_button_place > input, .sel_option > input
  {
    width : 80%;
    text-align: center;
    border-radius: 5px;
    font-size: 1.2rem;
    border: 0;
  }
  .upper_button_place  > select
  {
    width: 30%;
    text-align: center;
    font-weight: bolder;
    font-size: 1rem;
    border-radius: 5px;
  }
  .letter_btn
  {
    width: auto;
    display: flex;
    background-color: white;
    border: 0;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    cursor: pointer;
    justify-content: center;
  }
  .paging_number_place
  {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .paging_number
  {
    width: 10%;
    display: flex;
    background-color: white;
    border: 0;
    text-align: center;
    font-size: 0.8em;
    font-weight: bold;
    cursor: pointer;
    justify-content: center;
  }

  h3
  {
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
  }
  .sel_option
  {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    white-space: pre;
    margin-top: 5px;
  }
  .category, .word_tag
  {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    white-space: pre;
  }
  .category > select
  {
    margin-top: 5px;
    width: 100%;
    border-radius: 10px;
    border: 0;
    font-size: 1.1rem;
    text-align: center;
  }
  .word_tag input
  {
    margin-top: 5px;
    width: 100%;
    font-size: 1.1rem;
    font-weight: bolder;
    border: 0;
    text-align: center;
  }
  .word_tag input[type="text"]::placeholder 
  {
    text-align: center;
  }
  .answer_box
  {
    font-size: 1rem;
    font-weight: bold;
    width: 100%;
    height : 30px;
    resize: none;
    overflow: none;
    border: 0;
    text-align: center;
    align-items: center;
  }
  .insert_tag input
  {
    width: 100%;
    font-size: 1rem;
    font-weight: bolder;
    border: 0;
    text-align: center;
  }
  .insert_tag input[type="text"]::placeholder 
  {
    text-align: center;
  }
  .insert_tag
  {
    min-width: 22%;
    max-width: 34%;
    width: auto;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
  }

  .question_sub
  {
    display: flex;
    width: 100%;
    height: 80%;
    flex-direction: column;
    justify-content: center;    
    align-items: center;
    margin-top: 15px;
  }
  .question_row
  {
    width: 95%;
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: space-around;
    margin-top: 2.5px;
    margin-bottom: 2.5px;
  }
  .question_tag
  {
    width: 20%;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
  }
  .question_input
  {
    width: 70%;
    max-width: 70%;
    min-height: 20px;
    resize: none;
  }
  .longtext_input
  {
    width: 70%;
    max-width: 70%;
    min-height: 150px;
    resize: none;
  }
  .question_input::placeholder, .longtext_input::placeholder
  {
    display: flex;
    height: 100%;
    text-align: center;
    font-size: 1rem;
  }
  .img_input
  {
    width: 70%;
    max-width: 70%;
    font-size: 0.9rem !important;
  }

  /*삭제예정 시작*/
  .paragraph_area
  {
    width: 48%;
  }
  .paragraph_area textarea
  {
    width: 100%;
    height: 90%;
    resize: none;
    overflow: auto;
    max-height: 80vh;
    text-align: center;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .paragraph_area textarea::placeholder 
  {
    display: flex;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .question_area
  {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5px;
  }
  .question_area textarea
  {
    font-size: 1rem;
    width: 100%;
    height: 40px;
    resize: none;
    overflow: auto;
    text-align: center;
    align-items: center;
  }
/*삭제예정 종료*/
  .modal_design
  {
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
  }
  .textarea_design
  {
    width : 100%;
    height: 1%;
    resize: none;
    text-align: center;
    vertical-align: middle;
    font-size: 1rem;
  }
  .small_letter_btn
  {
    width: auto;
    display: block;
    background-color: white;
    border: 0;
    text-align: center;
    font-size: 0.7em;
    font-weight: bold;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .value_place
  {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;    
  }
  .value
  {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: auto;
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .value > p
  {
    width: auto;
    white-space: nowrap;
  }
  .remove_button
  {
    border: 0;
    background-color: white;
    margin-left: 2%;
    margin-right: 2%;
  }
  .remove_button:hover
  {
    color: red;
  }
  .small_input
  {
    width : 25%;
    text-align: center;
    border-radius: 5px;
    font-size: 1.2rem;
    border: 0;
  }
  .exam_info_place
  { 
    display: flex;
    width : 95%;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    max-width: 96%;
  }
  .exam_info_place > p 
  {
    width: auto;
    max-width: 1200px;
    display: flex;
    flex-direction: center;
    align-items: center;
  }
  .answer_place
  {
    margin-top: 20px;
    width: 85%;
    display: flex;
    justify-content: left;
    flex-direction: column;
  }
  .answer_place > p
  {
    margin-bottom: 10px;
    font-size: 1rem;
    font-weight: bold;
  }
  .move_button_place
  {
    display: flex;
    width: 80%;
    max-width: 1000px;
    margin-top: 30px;
    flex-direction: row;
    justify-content: space-around;
  }

  .popup
  {
    max-width: 80%;
  }

  .container
  {
    display: flex;
    width: auto;
    margin: 2.5%;
    max-width: 100%;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
  }
  .container_t
  {
    display: flex;
    width: auto;
    margin: 2.5%;
    max-width: 100%;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
  }
  .container_p
  {
    display: flex;
    width: auto;
    margin: 2.5%;
    max-width: 50%;
  }
  button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
  }
  button.public {
    background-color: green;
    color: white;
  }
  
  button.private {
    background-color: red;
    color: white;
  }
